@media (max-width: 1200px) {
  .linked-icon {
    width: 60px;
  }

  .claim-your-free-setup-strategy-section::before,
  .claim-your-free-setup-strategy-section::after {
    content: none;
  }
}
