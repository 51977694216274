#answerAccordion .accordion-button:not(.collapsed)::after,
#answerAccordion .accordion-button::after {
  filter: brightness(0) saturate(100%) invert(32%) sepia(19%) saturate(448%) hue-rotate(175deg) brightness(91%) contrast(89%);
}

#answerAccordion .accordion-item {
  border-left: 0px;
  border-right: 0px;
}

.accordion-button:focus,
.accordion-button:not(.collapsed) {
  box-shadow: none;
  border: 0px;
}

#answerAccordion .accordion-item.active {
  border: 0px;
}

#answerAccordion .accordion-collapse.show {
  border-bottom: 1px  solid rgba(0,0,0,.125)
}
