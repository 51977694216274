.content-industries::after {
  background-image: url('../../../assets/images/industries-education/Ellipse\ 15.jpg');
  background-size: contain;
  filter: invert(100%);
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 60%;
  width: 60%;
  z-index: -1;
}
