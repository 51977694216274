.claim-setup-form-section .claim-setup-form {
  position: relative;
}

.claim-setup-form-section .claim-setup-form::after {
  background-image: url('../../assets/images/home/Ellipse-right.jpg');
  background-size: contain;
  z-index: -2;
  top: -70px;
  right: -24px;
  height: 230px;
  width: 70px;
}

.generate-quality-leads::after {
  background-image: url('../../assets/images/outbound-content-marketing-distribution/Ellipse-circle.jpg');
  background-size: contain;
  z-index: 0;
  bottom: -46%;
  left: -80px;
  height: 410px;
  width: 410px;
  mix-blend-mode: multiply;
}

.we-guarantee-satisfaction::after {
  background-image: url('../../assets/images/home/Ellipse-left.jpg');
  background-size: contain;
  z-index: 2;
  bottom: 12%;
  left: -50px;
  height: 210px;
  width: 60px;
  mix-blend-mode: multiply;
}

@media screen and (min-width: 1024px) and (max-width: 1120px) {
  .generate-quality-leads::after {
    left: 0px;
  }
}

