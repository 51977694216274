.industries-education-section .card {
  min-height: 180px;
  text-align: left;
}


@media (max-width: 1440px) {
  .industries-education-section .card {
    min-height: 220px;
  }
}

@media (max-width: 1024px) {
  .industries-education-section .card {
    min-height: 200px;
  }
}

@media (max-width: 768px) {
  .industries-education-section .card {
    min-height: 170px;
  }
}
