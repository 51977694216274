.mobile-item {
  display: none;
}

@media (max-width: 1024px) {
  .desktop-item {
    display: none;
  }

  .mobile-item {
    display: block;
  }
}
