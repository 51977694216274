.we-guarantee-satisfaction .profile-info::after {
  background-image: url('../../../assets/images/outbound-content-marketing-distribution/quotes.jpg');
  background-size: contain;
  z-index: 1;
  top: -2px;
  left: 45px;
  height: 70px;
  width: 89px;
  mix-blend-mode: multiply;
}

.rating-card {
  background: #000;
}

.action-container {
  top: -40px;
  right: 0px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .small-screen-swipe-slider-vertical {
    height: 450px !important;
  }
}
