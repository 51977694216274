.content-section::after {
  background-image: url('../../../assets/images/home/Ellipse-left.jpg');
  background-size: contain;
  filter: invert(100%);
  z-index: -1;
  top: -100px;
  left: -13px;
  height: 210px;
  width: 60px;
}
