.dark-navbar {
  background: #000;
}
.dark-navbar .dropdown-item {
  color: #fff !important;
}
.dark-navbar .nav-link {
  color: #fff !important;
}
.dark-navbar .dropdown-menu {
  background: #000;
}
.dark-navbar .dropdown-item:focus, .dark-navbar .dropdown-item:hover {
  background-color: #6A9195;
  color: white;
}
.app__heading {
  color: #000;
  font-size: 24px;
}
.app__heading span {
  color: #007bff !important;
}

.navbar-toggler img {
  filter: invert(1);
}

@media (max-width: 1024px) {
  .navbar-toggler {
    display: block !important;
  }
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #6A9195;
  color: white;
}

.button-link-atag {
  text-decoration: none;
}