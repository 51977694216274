.contact-section-wrapper {
    background-color: #0A0D17;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 0;
  }
  
  .form-heading {
    text-align: center;
  }
  
  /* .contact-section {
    text-align: center;
  } */
  
  .title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #fff;
  }
  
  .title span {
    color: #4e94f3;
  }
  
  .subtitle {
    margin: 10px 0 30px;
    color: #c0c0c0;
  }
  
  .contact-container {
    background-color: #1c1c40;
  }
  
  .form-control::placeholder {
    color: #aaa;
  }
  
  .btn-primary {
    background-color: #007BFF;
    border: none;
  }

  
  .quote_wrap {
    position: relative;
  }

  .quote {
    font-size: 0.9rem;
    color: #c0c0c0;
    text-align: center;
    left: calc(var(--bs-gutter-x)/ 2);
    right: calc(var(--bs-gutter-x)/ 2);
    bottom: 25px;
    position: absolute;
  }
  
  .quote span {
    color: #4e94f3;
  }

  .input-bg{
    background-color :#FFFFFF0D !important;
  }

  .input-hug{
    height: 42px;
    border-color: #FFFFFF33;
  }

  .text-font{
    font-size: 22px;
  }

  .submit-button{
    height: 42px;
  }
  
  .textarea-height{
    height: 109px;
    border-color: #FFFFFF33;
  }