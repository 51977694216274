body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000 !important;
}

h1, h2, h3, h4, h5, h6, p, a, span {
  color: #fff !important;
}
.card-body {
  background: #000 !important;
}

.company-slider img {
  filter: invert(100%);
}

.card {
  background: #000 !important;
  border: 1px solid #f4f4f417 !important;
}

.content-header {
  background: #f4f4f417 !important;
}

.odd-plan .content-body {
  background: #f0f7f514 !important;
}

.swiper-pagination-bullet {
  background: #fff !important;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #007bff !important;
}

.d-none.d-sm-block.underline {
  border-bottom: 1px solid #e5e7eb21 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
