.simplify-section .card-body {
  min-height: 170px;
}

@media (max-width: 1440px) {
  .simplify-section .card-body {
    min-height: 220px;
  }
}

@media (max-width: 1024px) {
  .simplify-section .card-body {
    min-height: 200px;
  }

  .card-title.default-lg-description {
    font-size: 24px;
  }
}

@media (max-width: 768px) {
  .simplify-section .card-body {
    min-height: 170px;
  }
}
