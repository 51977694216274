.rating-card {
  border: 1px solid #000
}

.rating-card img {
  border-radius: 50%;
}

.swiper {
  position: static !important;
}

.swiper-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 3px;
  bottom: -10px !important;
}

.swiper-pagination-bullet-active {
  width: 20px;
  height: 20px;
  background-color: #007bff;
  color: #007bff;
}

@media (max-width: 1024px) {
  .swiper-pagination {
    bottom: -40px !important;
  }
}

@media (max-width: 992px) {
  .swiper-pagination {
    bottom: -50px !important;
  }
}
