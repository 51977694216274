.inbound-customer-services-items-section .card-body {
  min-height: 280px;
  padding: 30px 20px;
}

.inbound-customer-services-items-section-service .card-body {
  min-height: 260px;
}

.inbound-customer-services-items-section-omni-service .card-body {
  min-height: 320px;
}

@media (max-width: 1440px) {
  .inbound-customer-services-items-section .card-body {
    min-height: 360px;
  }

  .inbound-customer-services-items-section-service .card-body {
    min-height: 320px;
  }

  .inbound-customer-services-items-section-omni-service .card-body {
    min-height: 410px;
  }
}

@media (max-width: 1024px) {
  .inbound-customer-services-items-section .card-body {
    min-height: 300px;
  }

  .inbound-customer-services-items-section-service .card-body {
    min-height: 260px;
  }

  .inbound-customer-services-items-section-omni-service .card-body {
    min-height: 440px;
  }
}

@media (max-width: 768px) {
  .inbound-customer-services-items-section .card-body,
  .inbound-customer-services-items-section-service .card-body {
    min-height: 200px;
  }
}
