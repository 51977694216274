@font-face {
  font-display: swap;
  font-style: normal;
  src: url("../fonts/PoppinsSemiBold.ttf");
  font-family: "Poppins";
  font-weight: 600;
}

@font-face {
  font-display: swap;
  font-style: normal;
  src: url("../fonts/PoppinsBold.ttf");
  font-family: "Poppins";
  font-weight: 700;
}

@font-face {
  font-display: swap;
  font-style: normal;
  src: url("../fonts/PoppinsRegular.ttf");
  font-family: "Poppins";
  font-weight: 400;
}

@font-face {
  font-display: swap;
  font-style: normal;
  src: url("../fonts/PoppinsMedium.ttf");
  font-family: "Poppins";
  font-weight: 500;
}

@font-face {
  font-display: swap;
  font-style: normal;
  src: url("../fonts/PoppinsLight.ttf");
  font-family: "Poppins";
  font-weight: 300;
}

@font-face {
  font-display: swap;
  font-style: normal;
  src: url("../fonts/PoppinsExtraBold.ttf");
  font-family: "Poppins";
  font-weight: 800;
}

/* Define other @font-face rules for Inter and DM Sans similarly */

/* Poppins fonts */
.font-poppins {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

.font-poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

.font-poppins-semi-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.font-poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.font-poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}

.font-poppins-extra-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
}

/* Inter fonts */
.font-inter {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

.font-inter-bold {
  font-family: "Inter", sans-serif;
  font-weight: 700;
}

.font-inter-semi-bold {
  font-family: "Inter", sans-serif;
  font-weight: 600;
}

.font-inter-medium {
  font-family: "Inter", sans-serif;
  font-weight: 500;
}

.font-inter-light {
  font-family: "Inter", sans-serif;
  font-weight: 300;
}

.font-inter-extra-bold {
  font-family: "Inter", sans-serif;
  font-weight: 800;
}

/* DM Sans fonts */
.font-dm-sans {
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

.font-dm-sans-medium {
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
}
