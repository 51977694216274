.identify-the-customers-section .card-body {
  min-height: 180px;
}

@media (max-width: 1440px) {
  .identify-the-customers-section .card-body {
    min-height: 220px;
  }
}

@media (max-width: 1024px) {
  .identify-the-customers-section .card-body {
    min-height: 200px;
  }
}
