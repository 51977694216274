.footer {
  background: #000;
}
.footer .app__heading {
  color: #fff;
  font-size: 24px;
}
.footer .navbar-brand {
  display: flex;
}
.footer .social img {
  filter: brightness(0) invert(1);
}

.the-contact-center-auto {
  max-width: 200px;
  margin: auto;
}

.footer {
  z-index: 5
}
