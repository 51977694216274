.rectangle-image-section .display-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
}

.rectangle-image-section .rectangle-image {
  filter: invert(100%);
}
