.outbound-service-main-section::after {
  background-image: url('../../assets/images/home/Ellipse-left.jpg');
  background-size: contain;
  filter: invert(100%);
  z-index: -1;
  width: 50%;
  height: 100%;
  top: 0;
  left: -22%;
}

.outbound-service-main-section::before {
  background-image: url('../../assets/images/home/Ellipse-right.jpg');
  background-size: contain;
  filter: invert(100%);
  z-index: -1;
  width: 50%;
  height: 100%;
  bottom: 0;
  right: -22%;
}

@media (max-width: 540px) {
  .outbound-service-main-section::before,
  .outbound-service-main-section::after {
    content: none;
  }

  .outbound-service-item-content img {
    width: 40px;
    height: 40px;
  }
}

.our-process-section .underline:last-child {
  border: 0 !important;
}
