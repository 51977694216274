.jumbotron-section:before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  top: -2px;
  left: 4px;
  width: 100%;
  height: 100%;
  background: rgb(23, 23, 24, 30%);
  z-index: 1;
  border-radius: 20px;
}

.content-area {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  z-index: 3;
  width: 70%;
  text-align: left;
}

.content-area h1 {
  font-size: 40px;
  text-align: center;
}

.jumbotron-section-image {
  border-radius: 20px;
}
@media (max-width: 1024px) {
  .content-area p {
    font-size: 14px;
  } 

  .jumbotron-section-image {
    border-radius: 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1000px) {
  .content-area {
    width: 100%;
    bottom: 6%;
    left: 0px;
    transform: none;
  }
}

@media (max-width: 768px) {
  .content-area {
    padding: 10px;
  }
  .content-area h1 {
    font-size: 22px;
  }
  /* .c-text-center {
    text-align: center;
  }

  .c-custom-dark-color {
    color: #171718 !important
  }

  .claim-setup-form-section-leads .claim-setup-form::before {
    content: none;
  }

  .content-area {
    position: relative;
    left: 0px;
    top: 0px;
    transform: none;
    padding: 20px 10px;
    background-color: #fff;
    z-index: 4;
    width: 100%;
  }

  .content-area h1,
  .understand-the-individual-section h2 span {
    color: #171718 !important;
  }

  .content-area p {
    color: #4B5563;
    font-weight: 400;
  } */
}

@media (max-width: 767px) {
  /* .jumbotron-section:before {
    content: none;
  } */

  .company-logo {
    width: 58px;
  }
}
