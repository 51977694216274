.service-card {
  border: 0px;
  border-radius: 10px;
  box-shadow: rgb(211, 220, 255) 0px 2px 2px 0px, rgb(211, 220, 255) 0px 0px 0px 1px;
}

.service-card .card-body {
  padding: 30px 20px;
}

.service-icon {
  left: -30px;
  top: -25px;
}

@media (max-width: 520px) {
  .service-card .card-body {
    padding: 24px 24px;
  }

  .c-text-md-center {
    text-align: center;
  }

  .outbound-service-whatsapp {
    width: 40px;
  }
}
