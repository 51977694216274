.our-service-section::after {
  filter: invert(100%);
  background-image: url('../../assets/images/home/switch-left.jpg');
  background-size: contain;
  z-index: -1;
  top: -30%;
  left: -260px;
  height: 346px;
  min-width: 17%;
  width: clamp(200px, 30vw, 466px);
  height: clamp(150px, 30vw, 466px);
}

.our-service-section::before {
  filter: invert(100%);
  background-image: url('../../assets/images/home/switch-right.jpg');
  background-size: contain;
  z-index: -1;
  bottom: -30%;
  right: -250px;
  width: clamp(200px, 30vw, 466px);
  height: clamp(150px, 30vw, 466px);
}

.how-we-do-it-section::after {
  filter: invert(100%);
  background-image: url('../../assets/images/home/Ellipse-left.jpg');
  background-size: contain;
  z-index: -1;
  position: absolute;
  top: 70%;
  transform: translateY(-50%);
  left: -300px;
  width: clamp(200px, 30vw, 466px);
  height: clamp(150px, 30vw, 466px);
}

.home-testimonial-section::after {
  filter: invert(100%);
  background-image: url('../../assets/images/home/Ellipse-right.jpg');
  background-size: contain;
  z-index: -1;
  bottom: 56%;
  right: 0;
  width: clamp(80px, 20vw, 100px);
  height: clamp(150px, 20vw, 300px);
}


@media screen and (min-width: 992px) and (max-width: 1200px) {
  .our-service-section::after {
    top: -11%;
    left: -46px;
    height: 200px;
    min-width: 12%;
  }
}

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .our-service-section::after {
    filter: invert(100%);
    background-image: url('../../assets/images/home/md-switch-left.jpg');
    top: 36%;
    left: -135px;
    height: 20%;
    min-width: 30%;
  }

  .our-service-section::before {
    bottom: 20%;
    right: -140px;
    height: 230px;
    min-width: 35%;
  }

  .how-we-do-it-section::after {
    left: -200px;
    top: 25%;
  }
  .home-testimonial-section::after {
    right: -20px;
    bottom: 50%;
  }
}
