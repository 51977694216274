.industries-b2b-section .card {
  min-height: 280px;
}

@media (max-width: 1440px) {
  .industries-b2b-section .card {
    min-height: 300px;
  }
}

@media (max-width: 1024px) {
  .industries-b2b-section .card {
    min-height: 280px;
  }
}

@media (max-width: 768px) {
  .industries-b2b-section .card {
    min-height: 200px;
  }
}
