body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  color: #4B5563
}

#root {
  overflow: hidden;
}

.primary-bg {
  background-color: #6A9195 !important;
}

.primary-bg-light {
  /* background-color: #F0F7F5 !important; */
  background: #f4f4f417 !important;
}

.primary-bg-extra-light {
  /* background-color: #F5F5F5; */
  background: #f4f4f417 !important;
}

.primary-color {
  color: #007bff !important;
}

.extra-primary-color {
  color: #466E79 !important;
}

.secondary-color {
  color: #4B5563 !important;
}

.custom-dark-color {
  color: #fff !important;
  /* color: #171718 !important; */
}

.light-blue-color {
  color: #87CEEB;
}

.light-blue-bg {
  background-color: #87CEEB;
}

.primary-border {
  border: 1px solid #466E79
}

.primary-btn {
  min-width: 150px;
  height: 50px;
  margin-top: 10px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
}

.primary-btn:hover {
  background-color: #518389;
  color: #fff;
  cursor: pointer;
}

.-top-2 {
  top: -2px;
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 0.05rem;
}

.navbar-collapse-auto {
  flex-basis: auto !important;
}

.default-xl-title { /* done */
  font-size: 56px;
}

.default-extra-xl-title { /* done */
  font-size: 56px;
}

.default-privacy-title { /* done */
  font-size: 56px;
}

.default-extra-lg-title {
  font-size: clamp(42px, 6vw, 48px);
}

.default-lg-title { /* done */
  font-size: 48px;
}

.default-special-xl-title { /* done */
  font-size: 48px;
}

.default-extra-special-xl-title { /* done */
  font-size: 48px;
}

.default-special-lg-title {
  font-size: clamp(32px, 3vw, 48px);
}

.default-satisfaction-title { /* done */
  font-size: 42px
}

.default-title { /* done */
  font-size: clamp(24px, 3vw, 32px);
}

.default-md-title { /* done */
  font-size: 32px;
}

.default-special-md-title { /* done */
  font-size: 32px;
}

.default-sm-title { /* done */
  font-size: clamp(20px, 3vw, 24px);
}

.default-special-sm-title { /* done */
  font-size: 24px
}

.default-special-lg-description {
  font-size: clamp(12px, 2vw, 24px);
}

.default-lg-description { /* done */
  font-size: 24px;
}

.default-md-description {
  font-size: clamp(16px, 2vw, 20px);
}

.default-description { /* done */
  font-size: clamp(14px, 2vw, 16px);
}

.default-special-md-description { /* done */
  font-size: clamp(12px, 2vw, 16px);
}

.default-md-description-extra { /* done */
  font-size: 16px;
}

.default-special-description { /* done */
  font-size: clamp(12px, 2vw, 14px);
}

.default-sm-description {
  font-size: 16px
}

.static-description-14 {
  font-size: 14px;
}

.border-rounded-10 {
  border-radius: 10px !important;
}

.border-rounded-20 {
  border-radius: 10px !important;
}

.our-service-section::after,
.our-service-section::before,
.how-we-do-it-section::after ,
.outbound-service-main-section::after,
.outbound-service-main-section::before,
.home-testimonial-section::after,
.content-section::after,
.claim-setup-form::before,
.claim-setup-form-section .claim-setup-form::after,
.claim-setup-form-section-leads .claim-setup-form::before,
.generate-quality-leads::after,
.we-guarantee-satisfaction::after,
.we-guarantee-satisfaction .card-text:after,
.claim-your-free-setup-strategy-section:after,
.content-industries::after,
.claim-your-free-setup-strategy-section::before {
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  content: '';
}

.list-item-marker::marker {
  background-color: #6A9195;
  color: #6A9195;
  font-size: 30px;
}

.list-item-marker a {
  position: relative;
  left: -8px;
  top: -5px;
}

.publish-card-section .card {
  min-height: 220px;
}

@media (max-width: 1440px) {
  .publish-card-section .card {
    min-height: 245px;
  }
}

@media (max-width: 768px) {
  .publish-card-section .card {
    min-height: 200px;
  }
}

@media (max-width: 1024px) {
  .content-section::after,
  .claim-setup-form-section .claim-setup-form::after {
    content: none;
  }

  .actionable-lead-section .card-body {
    min-height: 140px !important;
  }

  .content-industries::after {
    height: 38% !important;
    width: 38% !important;
    transform: translateX(-20%) !important;
  }

  .publish-card-section .card .card-body {
    text-align: left !important;
  }

  .default-xl-title {
    font-size: 48px
  }

  .default-lg-title {
    font-size: 36px;
  }

  .default-sm-description {
    font-size: 14px
  }

  .default-md-title {
    font-size: 24px
  }

  .default-md-description-extra {
    font-size: 14px;
  }

  .default-special-md-title {
    font-size: 24px;
  }

  .default-extra-xl-title {
    font-size: 42px;
  }

  .default-lg-description {
    font-size: 20px;
  }

  .default-special-xl-title {
    font-size: 42px;
  }

  .default-extra-special-xl-title {
    font-size: 32px;
  }

  .default-special-sm-title {
    font-size: 22px
  }

  .default-satisfaction-title { /* done */
    font-size: 32px
  }

  .default-privacy-title {
    font-size: 36px;
  }
}

@media (max-width: 1023px) {
  .how-we-do-it-section::after,
  .our-service-section::before,
  .home-testimonial-section::after,
  .our-service-section::after,
  .claim-setup-form-section .claim-setup-form::before,
  .generate-quality-leads::after,
  .we-guarantee-satisfaction::after,
  .we-guarantee-satisfaction .card-text::after,
  .content-industries::after {
    content: none;
  }

  .publish-card-section .card .card-body {
    text-align: center !important;
  }

  .default-xl-title {
    font-size: 32px
  }

  .default-lg-title {
    font-size: 24px;
  }

  .default-sm-description {
    font-size: 12px
  }

  .default-md-title {
    font-size: 20px
  }

  .default-md-description-extra {
    font-size: 12px;
  }

  .default-special-md-title {
    font-size: 22px;
  }

  .default-extra-xl-title {
    font-size: 24px;
  }

  .default-lg-description {
    font-size: 16px;
  }

  .default-special-xl-title {
    font-size: 24px;
  }

  .default-extra-special-xl-title {
    font-size: 24px;
  }

  .default-special-sm-title {
    font-size: 20px
  }

  .default-satisfaction-title { /* done */
    font-size: 24px
  }

  .default-privacy-title {
    font-size: 24px;
  }
}

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .we-guarantee-satisfaction::after {
    left: -36px !important;
    bottom: 20% !important;
    height: 250px !important;
    width: 80px !important;
  }
}

/* Customizing bootstrap container class width */
@media (min-width: 768px) {
  .container, .container-md, .container-sm {
    max-width: 85%;
  }
}

@media (min-width: 992px) {
  .container, .container-lg, .container-md, .container-sm {
    max-width: 90%;
  }
}

@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 85%;
  }
}

@media (min-width: 1400px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
      max-width: 85%;
  }
}
/* Customizing bootstrap container class width */
