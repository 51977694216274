.lets-grow-your-audience-form input:focus,
.lets-grow-your-audience-form textarea:focus {
  border-color: #6A9195;
  outline: 0;
  box-shadow: rgba(99, 229, 199, 0.1) 0px 4px 12px;
}

.lets-grow-your-audience-form input,
.lets-grow-your-audience-form textarea {
  padding: 12px 20px;
}
