.actionable-lead-section .card-body {
  padding: 20px;
  min-height: 200px;
}

@media (max-width: 1440px) {
  .actionable-lead-section .card-body {
    min-height: 228px;
  }
}

.plans-table-section .plan-item span {
  width: 80px;
}

.plans-table-section .content-header,
.plans-table-section .card {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.plans-table-section .even-plan .content-header {
  background-color: #F3F4F6;
}

.plans-table-section .odd-plan .content-header {
  background-color: #B3D9CD
}

.plans-table-section .odd-plan .content-body {
  background-color: #F0F7F5
}

.actionable-lead-section .card-title {
  font-size: 24px !important;
}

.swipe-plan-slider {
  overflow: visible !important;
}
